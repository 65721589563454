import React from "react"
import Layout from "../../components/layout/Layout"
import "./TerminosCondiciones.css"

export default () => {
  return (
    <Layout type="HMNF" title="Términos y condiciones">
      <div className="terms-content">
        <h2>TÉRMINOS Y CONDICIONES</h2>
        <p>
          Este documento describe los términos y condiciones generales (los
          "Términos y Condiciones Generales") aplicables al acceso y uso de los
          servicios ofrecidos por TALLER DE CONFECCIONES SAN LUIS S.A. ("los
          Servicios") dentro del sitio web kidsmadehere.com, sus subdominios y/u
          otros dominios (urls) relacionados (en adelante el "Sitio"), en donde
          éstos Términos y Condiciones se encuentren. Cualquier persona que
          desee acceder y/o suscribirse y/o usar el Sitio o los Servicios podrá
          hacerlo sujetándose a los Términos y Condiciones Generales, junto con
          todas las demás políticas y principios que rigen kidsmadehere.com y
          que son incorporados al presente directamente o por referencia o que
          son explicados y/o detallados en otras secciones del Sitio. En
          consecuencia, todas las visitas y todos los contratos y transacciones
          que se realicen en este Sitio, así como sus efectos jurídicos,
          quedarán regidos por estas reglas y sometidos a la legislación
          aplicable en Perú.
        </p>
        <p>
          Los Términos y Condiciones contenidos en este instrumento se aplicarán
          y se entenderán como parte integral de todos los actos y contratos que
          se ejecuten o celebren mediante los sistemas de oferta y
          comercialización comprendidos en este sitio entre los usuarios de este
          sitio y por cualquiera de las otras sociedades o empresas que sean
          filiales o vinculadas y que hagan uso de este sitio, a las cuales se
          las denominará en adelante también en forma indistinta como las
          "Empresas", o bien la "Empresa Oferente".
        </p>
        <p>
          El Usuario debe leer, entender y aceptar todas las condiciones
          establecidas en los Términos y Condiciones Generales y en las
          Políticas de Privacidad de TALLER DE CONFECCIONES SAN LUIS S.A. así
          como en los demás documentos incorporados a los mismos por referencia,
          previo a su registro como Usuario en kidsmadehere.com y/o a la
          adquisición de productos y/o entrega de cualquier dato con cualquier
          fin.
        </p>
        <h3>1. Capacidad legal</h3>
        <p>
          Los Servicios sólo están disponibles para personas que tengan
          capacidad legal para contratar. No podrán utilizar los servicios las
          personas que no tengan esa capacidad entre estos los menores de edad o
          Usuarios de kidsmadehere.com que hayan sido suspendidos temporalmente
          o inhabilitados definitivamente en razón de lo dispuesto en la sección
          2 “Registro y Uso del Sitio”. Los actos que los menores realicen en
          este sitio serán responsabilidad de sus padres, tutores, encargados o
          curadores, y por tanto se considerarán realizados por éstos en
          ejercicio de la representación legal con la que cuentan
        </p>
        <p>.................</p>
        <h3>19. Jurisdicción y Ley aplicable</h3>
        <p>
          Este acuerdo estará regido en todos sus puntos por las leyes vigentes
          en la República del Perú. Cualquier controversia derivada del presente
          acuerdo, su existencia, validez, interpretación, alcance o
          cumplimiento, será sometida a los tribunales competentes de la ciudad
          de Lima, Perú.
        </p>
      </div>
    </Layout>
  )
}
